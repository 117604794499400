import { useMutation, useSuspenseQuery } from '@apollo/client'
import { GET_BUSINESS_PROFILE, UPDATE_BUSINESS_PROFILE } from '../../../graphql/business-profile-queries'
import { useContext, useState } from 'react'
import { Box, Button, FormControl, FormLabel, Grid, Input, Textarea, Typography } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { LayoutContext } from '../../../layouts/LayoutContextProvider'
import { GET_LEADS } from '../../../graphql/job-queries'
import { colors } from '../../../layouts/Theme'
import EngineeringIcon from '@mui/icons-material/Engineering'
import { UpdateBusinessProfileAttributes } from '../../../types/business-profile-type'

export default function OnboardingProfilePage() {
  const navigate = useNavigate()
  const { showAlert } = useContext(LayoutContext)
  const {
    data: {
      businessProfile: {
        name,
        yearEstablished,
        summary,
        address,
        contactNumber,
        email,
        socialMediaLink,
        logoUrl
      }
    }
  } = useSuspenseQuery(GET_BUSINESS_PROFILE)

  const { register, handleSubmit, setValue } = useForm<UpdateBusinessProfileAttributes>({
    defaultValues: {
      name,
      yearEstablished,
      summary,
      address,
      contactNumber,
      email,
      socialMediaLink,
    }
  })

  const [updateBusinessProfile, { loading }] = useMutation(UPDATE_BUSINESS_PROFILE)
  const [logoPreview, setLogoPreview] = useState(logoUrl)

  function handleLogoChange(e: React.ChangeEvent<HTMLInputElement>) {
    // TODO: Move this in a centralized place
    const MAX_FILE_SIZE = 5 * 1024 * 1024
    const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/gif']
    const errors: string[] = []

    const selectedFile = e.target.files?.[0]

    if (!selectedFile) {
      return
    } else if (selectedFile.size > MAX_FILE_SIZE) {
      errors.push(`${selectedFile.name} exceeds the maximum size of 5MB`)
    } else if (!ALLOWED_FILE_TYPES.includes(selectedFile.type)) {
      errors.push(`${selectedFile.name} is not a supported image type. Supported image types are: .jpeg, .png, and .gif`)
    }

    if (errors.length === 0) {
      setValue('logo', selectedFile)
      setLogoPreview(URL.createObjectURL(selectedFile))
    } else {
      showAlert(errors.join("\n"), 'danger')
    }
  }

  function onSubmit(attributes: UpdateBusinessProfileAttributes) {
    updateBusinessProfile({
      variables: { attributes },
      refetchQueries: [{ query: GET_LEADS }],
      onCompleted: ({ updateBusinessProfile: { errors }}) => {
        if (errors.length) {
          showAlert(errors.join('\n\n'), 'danger')
        } else {
          navigate('/business/leads')
          showAlert('Your profile has been saved. You can now browse leads!')
        }
      }
    })
  }

  return (
    <Grid container>
      <Grid xs={12}>
        <Box sx={{ mb: 3, display: 'flex', gap: 1, justifyContent: 'space-between' }}>
          <Button color="neutral" variant="outlined" onClick={() => navigate('/business/onboarding/categories')}>Back</Button>
          <Button color="neutral" variant="outlined" onClick={() => navigate('/business/leads')}>Skip for now</Button>
        </Box>
      </Grid>
      <Grid xs={12} sx={{ mb: 1 }}>
        <Typography level="h3">Business Profile</Typography>
        <Typography level="body-sm">Matatanggap ni client ang iyong profile once mag submit ka ng estimate. Importanteng tama at informative ang iyong business profile.</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} mt={1}>
            <Grid xs={12}>
              <Button variant="plain" component="label" sx={{ pl: 0, bgcolor: 'transparent', '&:hover': { bgcolor: 'transparent' } }}>
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '75px', height: '75px', backgroundColor: logoPreview ? colors.white : colors.primaryMuted, color: colors.primary, borderRadius: '50%' }}>
                    {logoPreview && <img src={logoPreview} style={{ objectFit: 'cover', width: '75px', height: '75px', borderRadius: '50%' }} />}
                    {!logoPreview && <EngineeringIcon />}
                  </Box>
                  <input
                    style={{
                      height: '1px',
                      width: '1px',
                      clip: 'rect(0 0 0 0)',
                      clipPath: 'inset(50%)',
                      overflow: 'hidden',
                      position: 'absolute',
                      bottom: 0,
                      left: 0
                    }}
                    type="file"
                    onChange={handleLogoChange}
                  />
                  <Typography level="body-sm" sx={{ display: 'block', mt: 1, color: colors.fontColor }}>Upload Logo</Typography>
                </Box>
              </Button>
            </Grid>
            <Grid xs={12} md={6}>
              <FormControl>
                <FormLabel>Business Name</FormLabel>
                <Input {...register("name")} placeholder="Business name" />
              </FormControl>
              <FormControl sx={{ mt: '15px' }}>
                <FormLabel>Year Established</FormLabel>
                <Input {...register("yearEstablished")} placeholder="Year Established" />
              </FormControl>
              <FormControl sx={{ mt: '15px' }}>
                <FormLabel>Summary</FormLabel>
                <Textarea {...register("summary")} minRows={5} placeholder="Provide a short summary of your business offerings" />
              </FormControl>
            </Grid>
            <Grid xs={12} md={6}>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input {...register("address")} placeholder="Address" />
              </FormControl>
              <FormControl sx={{ mt: '15px' }}>
                <FormLabel>Contact number</FormLabel>
                <Input {...register("contactNumber")} placeholder="Contact number" />
              </FormControl>
              <FormControl sx={{ mt: '20px' }}>
                <FormLabel>Email</FormLabel>
                <Input {...register("email")} placeholder="Email" />
              </FormControl>
              <FormControl sx={{ mt: '25px' }}>
                <FormLabel>Social Media Account</FormLabel>
                <Input {...register("socialMediaLink")} placeholder="Social Media Page or Account" />
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3, display: 'flex', gap: 1, justifyContent: 'space-between' }}>
            <Button type="submit" disabled={loading}>Save and View Leads</Button>
          </Box>
        </form>
      </Grid>
    </Grid>
  )
}
