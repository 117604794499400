import { Box, FormControl, FormLabel, Select, Option, Input } from '@mui/joy'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { ArchitectureJobAttributes, JobAttributes } from '../../types/job-type'

export default function ArchitectureForm({ register, control, attrs }: {
  register: UseFormRegister<JobAttributes>
  control: Control<JobAttributes>
  attrs: ArchitectureJobAttributes
}) {

  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: '50%' }}}>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Architecture Service</FormLabel>
        <Controller
          name="categoryAttrsValues.serviceType"
          defaultValue={attrs?.serviceType}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="What service do you need?"
            >
              <Option value="Conceptual Design">Conceptual Design</Option>
              <Option value="Schematic Design">Schematic Design</Option>
              <Option value="Design Development">Design Development</Option>
              <Option value="Construction Documentation">Construction Documentation</Option>
              <Option value="Site Planning">Site Planning</Option>
              <Option value="Interior Architecture">Interior Architecture</Option>
              <Option value="Landscape Design">Landscape Design</Option>
              <Option value="Feasibility Studies">Feasibility Studies</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Preferred Style</FormLabel>
        <Controller
          name="categoryAttrsValues.stylePreference"
          defaultValue={attrs?.stylePreference}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="What's your style preference?"
            >
              <Option value="Scandinavian">Scandinavian</Option>
              <Option value="Modern">Modern</Option>
              <Option value="Industrial">Industrial</Option>
              <Option value="Bohemian">Bohemian</Option>
              <Option value="Minimalist">Minimalist</Option>
              <Option value="Traditional">Traditional</Option>
              <Option value="Mid-Century Modern">Mid-Century Modern</Option>
              <Option value="Rustic">Rustic</Option>
              <Option value="Farmhouse">Farmhouse</Option>
              <Option value="Contemporary">Contemporary</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Floor area (sqm)</FormLabel>
        <Input {...register('categoryAttrsValues.floorArea')} defaultValue={attrs?.floorArea} required type="number" placeholder="Floor area e.g. 200" />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Special Requirements</FormLabel>
        <Controller
          name="categoryAttrsValues.specialRequirement"
          defaultValue={attrs?.specialRequirement}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Select special requirement"
            >
              <Option value="Accessibility Features">Accessibility Features</Option>
              <Option value="Eco-friendly Design">Eco-friendly Design</Option>
              <Option value="Energy-efficient Systems">Energy-efficient Systems</Option>
              <Option value="Smart Home Technology">Smart Home Technology</Option>
              <Option value="Soundproofing">Soundproofing</Option>
              <Option value="Sustainable Materials">Sustainable Materials</Option>
              <Option value="Security Systems">Security Systems</Option>
              <Option value="Fire Safety Measures">Fire Safety Measures</Option>
              <Option value="None">None</Option>
            </Select>
          )}
        />
      </FormControl>

    </Box>
  )
}
