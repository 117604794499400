//@ts-expect-error: This is needed by Rails
import React, { ChangeEvent, useContext, useState } from 'react'
import Typography from '@mui/joy/Typography'
import { Box, Grid } from '@mui/joy'
import { useLocation } from 'react-router-dom'
import TopUpSelector from '../../components/TopUpSelector'
import PaymentRedirectNotice from '../../components/PaymentRedirectNotice'
import { BusinessUserContext } from '../../layouts/business/BusinessUserContextProvider'
import { NumericFormat } from 'react-number-format'

export default function WalletPage() {
  const location = useLocation()
  const currentUrl = `${window.location.origin}${location.pathname}`
  const user = useContext(BusinessUserContext)
  const [generatingInvoice, setGeneratingInvoice] = useState(false)

  if (generatingInvoice) {
    return <PaymentRedirectNotice />
  }

  return (
    <Grid container sx={{ mb: '20px' }}>
      <Grid xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography level="h1" sx={{ mb: 2 }}>Your Wallet</Typography>
      </Grid>
      <Grid xs={12} lg={9}>
        <Box sx={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '20px' }}>
          <Box sx={{ mb: 3 }}>
            <Typography level="h3" sx={{ mb: 1 }}>
              <NumericFormat thousandSeparator="," displayType="text" prefix="Balance: ₱" value={user.walletBalance} />
            </Typography>
            <Typography level="body-sm">Estimate on Free Jobs: {user.estimateOnFreeJob}</Typography>
            <Typography level="body-sm">Estimate on Promoted Jobs: {user.estimateOnPromotedJob}</Typography>
            <Typography level="body-sm">Estimate on High Value Jobs: {user.estimateOnHighValueJob}</Typography>
          </Box>
          <TopUpSelector redirectUrl={currentUrl} setGeneratingInvoice={setGeneratingInvoice} />
        </Box>
      </Grid>
    </Grid>
  )
}
