import { Box } from '@mui/joy'
import useCsrfToken from '../hooks/use-csrf-token'
import { colors } from '../layouts/Theme'
import { BarLoader } from 'react-spinners'
import { isEmpty } from 'lodash'

export default function GoogleLogin({ userType }: { userType: 'business' | 'customer' }) {
  const csrfToken = useCsrfToken()
  const source = localStorage.getItem('hm:source')
  const referralCode = localStorage.getItem('hm:referralCode')

  if (!csrfToken || isEmpty(csrfToken)) {
    return <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}><BarLoader color={colors.primary} width={150} /></Box>
  }

  return (
    <form action={`/auth/google_oauth2?type=${userType}&source=${source}&referral_code=${referralCode}`} method="post">
      <input type="hidden" name="authenticity_token" value={csrfToken} />
      <button type="submit" className="google-login-btn"
        style={{
          display: 'flex',
          width: '100%',
          borderRadius: '4px',
          padding: '4px 8px',
          alignItems: 'center',
          height: '45px',
          backgroundColor: 'white',
          border: `1px solid ${colors.gray2}`,
          color: colors.fontColor,
          marginBottom: '10px'
        }}
      >
        <img src="/google-icon.png" width="25px" alt="Google icon" />
        <span style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>Continue with Google</span>
      </button>
    </form>
  )
}
