import { useSuspenseQuery } from "@apollo/client";
import { Box, Button, FormControl, Grid, Input, Table, Typography } from "@mui/joy";
import { GET_AMBASSADOR_CUSTOMERS } from "../../graphql/ambassador-queries";
import { useState } from "react";
import dayjs from "dayjs";

export default function AmbassadorPage() {
  const [query, setQuery] = useState('')
  const [finalQuery, setFinalQuery] = useState<string>()
  const { data: { ambassadorCustomers }} = useSuspenseQuery(GET_AMBASSADOR_CUSTOMERS, { variables: { q: finalQuery} })

  function handleSearch(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    if (query) {
      setFinalQuery(query)
    }
  }

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}>
          Ambassador Dashboard
        </Typography>
      </Grid>
      <Grid xs={12}>
        <form onSubmit={handleSearch}>
          <FormControl sx={{ mb: 2 }} orientation="horizontal">
            <Input type="text" placeholder="Search" value={query} onChange={({ target: { value }}) => setQuery(value)} sx={{ width: '100%' }} />
            <Button sx={{ ml: 1 }} type="submit">Search</Button>
            <Button sx={{ ml: 1 }} color="neutral" variant="outlined" onClick={() => { setFinalQuery(''); setQuery('') }}>Clear</Button>
          </FormControl>
        </form>
        <Box sx={{ bgcolor: 'white', borderRadius: '10px', px: 1 }}>
          <Table aria-label="basic table" sx={{ fontSize: '12px' }}>
            <thead>
              <th style={{width: '50%', backgroundColor: '#FFFFFF'}}>Client Name</th>
              <th style={{width: '30%', backgroundColor: '#FFFFFF'}}>Date Joined</th>
              <th style={{width: '20%', backgroundColor: '#FFFFFF'}}>Verified</th>
            </thead>
            <tbody>
              {ambassadorCustomers.length ?
                ambassadorCustomers.map((customer) => (
                  <tr>
                    <td>{customer.fullName}</td>
                    <td>{dayjs(customer.createdAt).format('MM-DD-YY hh:mmA')}</td>
                    <td>{customer.referralVerified ? 'Yes' : 'No'}</td>
                  </tr>
                )) : <tr style={{ padding: '10px' }}><td colSpan={3}>Wala pang clients ang gumagamit ng iyong referral</td></tr>}
            </tbody>
          </Table>
        </Box>
      </Grid>
    </Grid>
  )
}
