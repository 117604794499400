import { Box, Button, FormControl, FormLabel, Grid, Input, Typography } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useContext, useState } from 'react'
import { LayoutContext } from '../../layouts/LayoutContextProvider'
import useCsrfToken from '../../hooks/use-csrf-token'

interface CompleteRegistrationForm {
  full_name: string
  password: string
}

export default function CustomerRegistrationPage() {
  const { token } = useParams()
  const csrfToken = useCsrfToken()
  const { showAlert } = useContext(LayoutContext)
  const { register, handleSubmit } = useForm<CompleteRegistrationForm>()
  const [submitting, setSubmitting] = useState(false)

  async function onSubmit(formData: CompleteRegistrationForm) {
    const body = JSON.stringify({ ...formData, token })

    try {
      setSubmitting(true)
      const response = await fetch('/api/v1/complete_customer_registration', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken!
        },
        body
      })
      const json = await response.json()

      if (response.ok) {
        window.location.href = json.redirect
      } else {
        setSubmitting(false)
        showAlert(json.message, 'danger')
      }
    } catch(error) {
      alert("Something went wrong! We're on it")
      console.error(error)
    }
  }

  return (
    <Grid container justifyContent="center">
      <Grid xs={12} md={5}>
        <Box sx={{ p: '20px', backgroundColor: '#FFF', borderRadius: '20px' }}>
          <Typography level="title-lg">
            Complete Your Registration
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl sx={{ mt: 2, mb: 1 }}>
              <FormLabel required>Full Name</FormLabel>
              <Input required {...register("full_name")} autoFocus type="text" placeholder="Your Full Name" />
            </FormControl>
            <FormControl sx={{ mt: 2, mb: 1 }}>
              <FormLabel required>Password</FormLabel>
              <Input required {...register("password")} type="password" placeholder="Your Password" />
            </FormControl>
            <Button sx={{ mt: 4 }} type="submit" disabled={submitting}>Complete Registration</Button>
          </form>
        </Box>
      </Grid>
    </Grid>
  )
}
