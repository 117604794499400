import { Box, Typography } from '@mui/joy'
import { ArchitectureJobAttributes } from '../../types/job-type'
import { colors } from '../../layouts/Theme'

export default function ArchitectureDetails({ attrs }: { attrs: ArchitectureJobAttributes }) {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Service Needed</Typography>
        <Typography level="title-sm">{attrs.serviceType}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Style Preference</Typography>
        <Typography level="title-sm">{attrs.stylePreference}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Floor Area (sqm)</Typography>
        <Typography level="title-sm">{attrs.floorArea}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Special Requirement</Typography>
        <Typography level="title-sm">{attrs.specialRequirement}</Typography>
      </Box>
    </Box>
  )
}
