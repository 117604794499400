import { JobAttributes } from "../types/job-type"

export function fileListFromArray(files: File[]): FileList {
  const dataTransfer = new DataTransfer()
  files.forEach(file => dataTransfer.items.add(file))
  return dataTransfer.files
}

const hmUserEmails = ['ejaypcanaria@gmail.com','shirtstorehouse777@gmail.com']

export function isHMUser(email: string) {
  return hmUserEmails.includes(email)
}

export function usingFBBrowser() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  return userAgent.includes('FBAN') || userAgent.includes('FBAV') || userAgent.includes('Messenger')
}

export function extractJobFromSignUp(): JobAttributes | null {
  const newJobJson = localStorage.getItem('hm:newJob')

  if (newJobJson) {
    const jobAttributes = JSON.parse(newJobJson)
    const fileArray = jobAttributes.fileDataURLs.map((fileData: { name: string; type: string; dataUrl: string }) => {
      // Decode base64 and create a Blob
      const byteString = atob(fileData.dataUrl.split(',')[1])
      const arrayBuffer = new ArrayBuffer(byteString.length)
      const intArray = new Uint8Array(arrayBuffer)
      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([arrayBuffer], { type: fileData.type })
      return new File([blob], fileData.name, { type: fileData.type })
    });

    // Simulate a FileList by returning a read-only array of Files
    const fileList = fileArray as unknown as FileList
    delete jobAttributes.fileDataURLs
    delete jobAttributes.fileList

    return { ...jobAttributes, fileList }
  }
  return null
}
