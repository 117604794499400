import { Box, Button, FormControl, FormLabel, Grid, Input, Typography } from '@mui/joy'
import { useContext, useState } from 'react'
import { CustomerContext } from '../../layouts/customer/CustomerContextProvider'
import { colors } from '../../layouts/Theme'
import { useMutation } from '@apollo/client'
import { DELETE_CURRENT_CUSTOMER, GET_CURRENT_CUSTOMER, UPDATE_CURRENT_CUSTOMER_REFERRAL_CODE } from '../../graphql/user-queries'
import { LayoutContext } from '../../layouts/LayoutContextProvider'
import ConfirmAccountDeletionModal from '../../components/ConfirmAccountDeletionModal'
import useCsrfToken from '../../hooks/use-csrf-token'

async function signOut() {
  await fetch('/api/v1/customer_sessions', {
    method: "DELETE"
  })

  window.location.replace('/customer_login')
}

export default function CustomerAccountPage() {
  const user = useContext(CustomerContext)
  const csrfToken = useCsrfToken()
  const { showAlert } = useContext(LayoutContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [referralCode, setReferralCode] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const [deleteCustomer, { loading: saving }] = useMutation(DELETE_CURRENT_CUSTOMER, {
    onCompleted: ({ deleteCustomer: { errors, success }}) => {
      if (success) {
        signOut()
      } else {
        showAlert(errors[0], 'danger')
      }
    },
  })

  const [updateCustomerReferralCode, { loading: savingReferralCode }] = useMutation(UPDATE_CURRENT_CUSTOMER_REFERRAL_CODE, {
    refetchQueries: [{ query: GET_CURRENT_CUSTOMER }],
    onCompleted: ({ updateCustomerReferralCode: { errors }}) => {
      if (errors.length) {
        showAlert(errors[0], 'danger')
      } else {
        showAlert('Your referral code has been applied', 'success')
      }
    },
  })

  function handleUpdateReferralCode(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (referralCode.length) {
      updateCustomerReferralCode({ variables: { referralCode }})
    }
  }

  async function handleChangePassword(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    try {
      setSubmitting(true)
      const response = await fetch('/api/v1/customer_change_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken!
        },
        body: JSON.stringify({
          current_password: currentPassword,
          new_password: newPassword,
          new_password_confirmation: newPasswordConfirmation
        })
      })

      const json = await response.json()
      setSubmitting(false)

      if (response.ok) {
        showAlert(json.message, 'success')
        setTimeout(() => window.location.reload(), 3000)
      } else {
        showAlert(json.message, 'danger')
      }
    } catch(error) {
      alert("Something went wrong! We're on it")
      console.error(error)
    }
  }

  return (
    <Grid container>
      <ConfirmAccountDeletionModal
        open={modalOpen}
        setOpen={setModalOpen}
        onConfirm={(confirmationEmail) => deleteCustomer({ variables: { confirmationEmail }})}
        disableButton={saving}
      />
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}>
          Account
        </Typography>
      </Grid>
      <Grid xs={12} md={9}>
        <Box sx={{ position: 'relative', backgroundColor: '#FFF', padding: '20px', mb: 2, borderRadius: '20px' }}>
          <Typography sx={{ mb: 2 }} level="h3">User Details</Typography>
          <Box sx={{ width: { xs: '100%', md: '50%' }}}>
            <FormControl>
              <FormLabel>
                Full Name
              </FormLabel>
              <Input disabled value={user.fullName} />
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <FormLabel>
                Email Address
              </FormLabel>
              <Input disabled value={user.email} />
            </FormControl>
          </Box>
          {!user.provider &&
            <Box sx={{ width: { xs: '100%', md: '50%' }}}>
              <Typography sx={{ mb: 1, mt: 4 }} level="h3">Change Password</Typography>
              <form onSubmit={handleChangePassword}>
                <FormControl sx={{ mt: 2, mb: 1 }}>
                  <FormLabel>Current Password</FormLabel>
                  <Input required autoFocus
                    type="password"
                    placeholder="Your current password"
                    value={currentPassword}
                    onChange={({ target: { value }}) => setCurrentPassword(value)}
                  />
                </FormControl>
                <FormControl sx={{ mt: 2, mb: 1 }}>
                  <FormLabel>New Password</FormLabel>
                  <Input required
                    type="password"
                    placeholder="Your new password"
                    value={newPassword}
                    onChange={({ target: { value }}) => setNewPassword(value)}
                  />
                </FormControl>
                <FormControl sx={{ mt: 2, mb: 1 }}>
                  <FormLabel>Confirm New Password</FormLabel>
                  <Input required
                    type="password"
                    placeholder="Re-type your new password"
                    value={newPasswordConfirmation}
                    onChange={({ target: { value }}) => setNewPasswordConfirmation(value)}
                  />
                </FormControl>
                <Button variant="outlined" color="neutral" sx={{ mt: 2 }} type="submit" disabled={submitting}>Change Password</Button>
              </form>
            </Box>}
            <Box sx={{ width: { xs: '100%', md: '50%' }}}>
              <Typography sx={{ mb: 1, mt: 4 }} level="h3">Referral Code</Typography>
              <form onSubmit={handleUpdateReferralCode}>
                <FormControl sx={{ mt: 1, mb: 1 }}>
                  <Input required autoFocus
                    disabled={!!user.referralCode}
                    type="text"
                    placeholder="Enter referral code"
                    value={referralCode || user.referralCode}
                    onChange={({ target: { value }}) => setReferralCode(value)}
                  />
                </FormControl>
                {!user.referralCode &&
                  <Button
                    sx={{ mt: 1 }}
                    color="neutral"
                    variant="outlined"
                    type="submit"
                    disabled={savingReferralCode}
                  >
                    Save Referral Code
                  </Button>}
              </form>
            </Box>
          <Box>
            <Typography sx={{ mb: 1, mt: 4 }} level="h3">Account Deletion Request</Typography>
            <Typography level="body-sm" sx={{ width: { xs: '100%', md: '50%' }, color: colors.gray3, mb: 2}}>
              If you wish to delete your account permanently, click the button below.
            </Typography>
            <Button color="danger" onClick={() => setModalOpen(true)}>Delete My Account</Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
