//@ts-expect-error: This is needed by Rails
import React, { useContext, useState } from 'react'
import { Box, Modal, ModalDialog, Typography } from "@mui/joy"
import TopUpSelector, { TopUpSelectorProps } from './TopUpSelector'

export default function TopUpSelectorModal({
  title, description, setGeneratingInvoice, redirectUrl, open, setOpen
}: TopUpSelectorProps & { title: string, description: string, open: boolean, setOpen: (val: boolean) => void }) {

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog sx={{ width: { xs: '90%', md: '50%' }}}>
        <Box>
          <Typography level="h3">{title}</Typography>
          <Typography level="body-md">{description}</Typography>
        </Box>
        <Box sx={{ overflow: 'auto', mt: 2 }}>
          <TopUpSelector setGeneratingInvoice={setGeneratingInvoice} redirectUrl={redirectUrl} />
        </Box>
      </ModalDialog>
    </Modal>
  )
}
