import { Box, FormControl, FormLabel, Select, Option, Input } from '@mui/joy'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { InteriorDesignJobAttributes, JobAttributes } from '../../types/job-type'

export default function InteriorDesignForm({ register, control, attrs }: {
  register: UseFormRegister<JobAttributes>
  control: Control<JobAttributes>
  attrs: InteriorDesignJobAttributes
}) {

  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: '50%' }}}>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Interior Design Service</FormLabel>
        <Controller
          name="categoryAttrsValues.serviceType"
          defaultValue={attrs?.serviceType}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="What service do you need?"
            >
              <Option value="Space Planning">Space Planning</Option>
              <Option value="Color Consultation">Color Consultation</Option>
              <Option value="Furniture Selection">Furniture Selection</Option>
              <Option value="Lighting Design">Lighting Design</Option>
              <Option value="Custom Furniture Design">Custom Furniture Design</Option>
              <Option value="3D Visualization">3D Visualization</Option>
              <Option value="Material and Finish Selection">Material and Finish Selection</Option>
              <Option value="Curtain and Window Treatment Design">Curtain and Window Treatment Design</Option>
              <Option value="Home Staging">Home Staging</Option>
              <Option value="Renovation Design">Renovation Design</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Preferred Style</FormLabel>
        <Controller
          name="categoryAttrsValues.stylePreference"
          defaultValue={attrs?.stylePreference}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="What's your style preference?"
            >
              <Option value="Scandinavian">Scandinavian</Option>
              <Option value="Modern">Modern</Option>
              <Option value="Industrial">Industrial</Option>
              <Option value="Bohemian">Bohemian</Option>
              <Option value="Minimalist">Minimalist</Option>
              <Option value="Traditional">Traditional</Option>
              <Option value="Mid-Century Modern">Mid-Century Modern</Option>
              <Option value="Rustic">Rustic</Option>
              <Option value="Farmhouse">Farmhouse</Option>
              <Option value="Contemporary">Contemporary</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Floor area (sqm)</FormLabel>
        <Input {...register('categoryAttrsValues.floorArea')} defaultValue={attrs?.floorArea} required type="number" placeholder="Floor area e.g. 200" />
      </FormControl>
    </Box>
  )
}
