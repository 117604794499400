import { useEffect, useState } from 'react'

export default function useCsrfToken() {
  const [csrfToken, setCsrfToken] = useState<string | null>()

  useEffect(() => {
    setCsrfToken(
      document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
    )
  }, [])

  return csrfToken
}
