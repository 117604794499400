//@ts-expect-error: This is needed by Rails
import React from "react"
import { Navigate, createBrowserRouter, useRouteError } from 'react-router-dom'
import LeadsPage from "./pages/business/LeadsPage"
import EstimatesPage from "./pages/business/EstimatesPage"
import ProfilePage from "./pages/business/ProfilePage"
import BusinessLayout from "./layouts/business/Layout"
import CustomerLayout from "./layouts/customer/Layout"
import GetEstimatesPage from "./pages/customer/GetEstimatesPage"
import JobsPage from "./pages/customer/JobsPage"
import NewJobPage from "./pages/customer/NewJobPage"
import JobPage from './pages/customer/JobPage'
import ViewEstimatePage from "./pages/customer/ViewEstimatePage"
import CustomerNotificationsPage from "./pages/customer/NotificationsPage"
import BusinessNotificationsPage from "./pages/business/NotificationsPage"
import LeadPage from "./pages/business/LeadPage"
import EditJobPage from "./pages/customer/EditJobPage"
import CoveragePage from "./pages/business/CoveragePage"
import { Box } from "@mui/joy"
import DiscardedLeadsPage from "./pages/business/DiscardedLeadsPage"
import OnboardingLocationPage from "./pages/business/onboarding/LocationPage"
import OnboardingLayout from "./pages/business/onboarding/Layout"
import OnboardingCategoryPage from "./pages/business/onboarding/CategoryPage"
import OnboardingProfilePage from "./pages/business/onboarding/ProfilePage"
import BusinessMenu from "./components/BusinessMenu"
import CustomerMenu from "./components/CustomerMenu"
import CustomerAccountPage from "./pages/customer/AccountPage"
import BusinessAccountPage from "./pages/business/AccountPage"
import BusinessReviewsPage from "./pages/business/ReviewsPage"
import JobBusinessReviewsPage from "./pages/customer/JobBusinessReviewsPage"
import BusinessReviewPage from "./pages/business/ReviewPage"
import HomePage from "./pages/HomePage"
import PublicNewJobPage from "./pages/PublicNewJobPage"
import PublicLayout from "./layouts/PublicLayout"
import BusinessRegistrationPage from "./pages/public/BusinessRegistrationPage"
import BusinessLoginPage from "./pages/public/BusinessLoginPage"
import BusinessForgotPasswordPage from "./pages/public/BusinessForgotPasswordPage"
import BusinessResetPasswordPage from "./pages/public/BusinessResetPasswordPage"
import CustomerLoginPage from "./pages/public/CustomerLoginPage"
import CustomerRegistrationPage from "./pages/public/CustomerRegistrationPage"
import CustomerForgotPasswordPage from "./pages/public/CustomerForgotPasswordPage"
import CustomerResetPasswordPage from "./pages/public/CustomerResetPasswordPage"
import AmbassadorPage from "./pages/customer/AmbassadorPage"
import WalletPage from "./pages/business/WalletPage"

function ErrorBoundary() {
  const error = useRouteError()
  console.error(error)
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 20, height: '100vh' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ fontSize: '50px' }}>Page Not Found</Box>
        <Box>This is not the page you are looking for</Box>
      </Box>
    </Box>
  )
}

export const router = createBrowserRouter([
  {
    element: <PublicLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/r/:code',
        element: <HomePage />
      },
      {
        path: '/business_login',
        element: <BusinessLoginPage />
      },
      {
        path: '/business_forgot_password',
        element: <BusinessForgotPasswordPage />
      },
      {
        path: '/business_reset_password/:token',
        element: <BusinessResetPasswordPage />
      },
      {
        path: '/business_registration/:token',
        element: <BusinessRegistrationPage />
      },
      {
        path: '/customer_login',
        element: <CustomerLoginPage />
      },
      {
        path: '/customer_registration/:token',
        element: <CustomerRegistrationPage />
      },
      {
        path: '/customer_forgot_password',
        element: <CustomerForgotPasswordPage />
      },
      {
        path: '/customer_reset_password/:token',
        element: <CustomerResetPasswordPage />
      },
      {
        path: '/get_estimates/:categorySlug',
        element: <PublicNewJobPage />
      },
    ]
  },
  {
    element: <BusinessLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/business/account',
        element: <BusinessAccountPage />
      },
      {
        path: '/business/reviews',
        element: <BusinessReviewsPage />
      },
      {
        path: '/business/reviews/:id',
        element: <BusinessReviewPage />
      },
      {
        path: '/business/menu',
        element: <BusinessMenu />
      },
      {
        path: '/business/notifications',
        element: <BusinessNotificationsPage />
      },
      {
        path: '/business/leads',
        element: <LeadsPage />
      },
      {
        path: '/business/leads/:jobUuid',
        element: <LeadPage />
      },
      {
        path: '/business/estimates',
        element: <EstimatesPage />
      },
      {
        path: '/business/discarded-leads',
        element: <DiscardedLeadsPage />
      },
      {
        path: '/business/coverage',
        element: <CoveragePage />
      },
      {
        path: '/business/profile',
        element: <ProfilePage />
      },
      {
        path: '/business/wallet',
        element: <WalletPage />
      },
      {
        path: '/business/onboarding',
        element: <Navigate to="/business/onboarding/location" />
      },
      {
        element: <OnboardingLayout />,
        children: [
          {
            path: '/business/onboarding/location',
            element: <OnboardingLocationPage />
          },
          {
            path: '/business/onboarding/categories',
            element: <OnboardingCategoryPage />
          },
          {
            path: '/business/onboarding/profile',
            element: <OnboardingProfilePage />
          },
        ]
      }
    ]
  },
  {
    element: <CustomerLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/customer/ambassador',
        element: <AmbassadorPage />
      },
      {
        path: '/customer/account',
        element: <CustomerAccountPage />
      },
      {
        path: '/customer/menu',
        element: <CustomerMenu />
      },
      {
        path: '/customer/notifications',
        element: <CustomerNotificationsPage />
      },
      {
        path: '/customer/get-estimates',
        element: <GetEstimatesPage />
      },
      {
        path: '/customer/jobs',
        element: <JobsPage />
      },
      {
        path: '/customer/job/:jobId',
        element: <JobPage />
      },
      {
        path: '/customer/job/:jobId/business/:businessUserId/reviews',
        element: <JobBusinessReviewsPage />
      },
      {
        path: '/customer/job/:jobId/edit',
        element: <EditJobPage />
      },
      {
        path: '/customer/job/:jobId/estimate/:estimateId',
        element: <ViewEstimatePage />
      },
      {
        path: '/customer/new_job/:categorySlug/location/:locationId',
        element: <NewJobPage />
      },
    ]
  }
])
