import { Box, Modal, ModalDialog } from '@mui/joy'
import { BarLoader } from 'react-spinners'
import { colors } from '../layouts/Theme'

export default function PaymentRedirectNotice() {
  return (
    <Modal open>
      <ModalDialog sx={{ width: { xs: '90%', md: '40%' }}}>
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Box sx={{ mb: 4 }}>We are redirecting you to our payment provider. Please wait...</Box>
          <BarLoader color={colors.primary} width="100%" />
        </Box>
      </ModalDialog>
    </Modal>
  )
}
