import { Box, Button, FormControl, FormLabel, Input, Link, Typography } from '@mui/joy'
import FacebookLogin from './FacebookLogin'
import GoogleLogin from './GoogleLogin'
import AppleLogin from './AppleLogin'
import { colors, fontSizes } from '../layouts/Theme'
import { useContext, useEffect, useState } from 'react'
import EmailIcon from '@mui/icons-material/Email'
import { usingFBBrowser } from '../utils/general-utils'
import { LayoutContext } from '../layouts/LayoutContextProvider'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import { Link as NavLink, useNavigate } from 'react-router-dom'
import { BarLoader } from 'react-spinners'
import useCsrfToken from '../hooks/use-csrf-token'

export default function BusinessLoginOptions() {
  const [loading, setLoading] = useState(false)
  const [emailSelected, setEmailSelected] = useState(false)
  const [registerSelected, setRegisterSelected] = useState(false)
  const [fbBrowserDetected, setFbBrowserDetected] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [registrationMessage, setRegistrationMessage] = useState<string | null>(null)
  const csrfToken = useCsrfToken()
  const { showAlert } = useContext(LayoutContext)
  const navigate = useNavigate()
  const source = localStorage.getItem('hm:source')

  useEffect(() => {
    if (usingFBBrowser()) {
      setFbBrowserDetected(true)
    }
  }, [])

  async function handleLogIn(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setLoading(true)
    const response = await fetch('/api/v1/business_log_in', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken!
      },
      body: JSON.stringify({ email, password })
    })

    const json = await response.json()

    if (response.ok) {
      window.location.href = json.redirect
    } else {
      setLoading(false)
      showAlert(json.message, 'danger')
    }
  }

  async function handleRegistration(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setLoading(true)
    const response = await fetch('/api/v1/send_business_sign_up_link', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken!
      },
      body: JSON.stringify({ email, source })
    })

    const json = await response.json()

    if (response.ok) {
      setRegistrationMessage('We sent you an email with a link to complete the registration process')
    } else {
      setLoading(false)
      showAlert(json.message, 'danger')
    }
  }

  return (
    <Box sx={{ p: { xs: '0px 15px 20px', md: '10px 40px 15px' } }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1, mt: 1 }}>
        <Typography level="h1" sx={{ fontWeight: 500 }}>Business Login</Typography>
      </Box>
      <Typography textAlign="center" sx={{ lineHeight: '1.2rem', fontSize: fontSizes.regular, mb: 3 }}>
        Ang Business Login ay para sa mga contractors at service providers na nag hahanap ng clients
      </Typography>
      {csrfToken ? <Box>
        <FacebookLogin userType="business" />
        {!fbBrowserDetected && <GoogleLogin userType="business" />}
        <AppleLogin userType="business" />
        {!emailSelected && !registerSelected &&
          <Button
            onClick={() => setEmailSelected(true) }
            sx={{ mt: '10px', p: '10px 8px', fontWeight: 'normal', fontSize: 'md', borderRadius: '4px', width: '100%', bgcolor: 'white', border: `1px solid ${colors.gray2}`, color: colors.fontColor, '&:hover': { bgcolor: 'white' } }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
              <EmailIcon sx={{ color: colors.fontColor }} />
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mr: '10px' }}>
                <span>Continue with Email</span>
              </Box>
            </Box>
          </Button>}
        {emailSelected &&
          <Box sx={{ mt: 5 }}>
            <form onSubmit={handleLogIn}>
              <FormControl sx={{ mt: 2, mb: 1 }}>
                <FormLabel>Email Address</FormLabel>
                <Input required onChange={({ target: { value }}) => setEmail(value)} value={email} autoFocus type="email" placeholder="Your email address" />
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1 }}>
                <FormLabel>Password</FormLabel>
                <Input required onChange={({ target: { value }}) => setPassword(value)} value={password} type="password" placeholder="Your password" />
              </FormControl>
              <FormControl sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button sx={{ mt: 1 }} type="submit" disabled={loading}>Log In</Button>
                <Button sx={{ mt: 1 }} variant="outlined" color="neutral" onClick={() => { setEmailSelected(false); setRegisterSelected(true) }}>Create New Account</Button>
                <Link component={NavLink} to="/business_forgot_password" sx={{ fontSize: fontSizes.xs, color: colors.gray3, display: 'inline-block', textAlign: 'center', mt: 2 }}>Forgot Password?</Link>
              </FormControl>
            </form>
          </Box>}
        {registerSelected &&
          <Box sx={{ mt: 5 }}>
            <form onSubmit={handleRegistration}>
              {registrationMessage ?
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <MarkEmailReadIcon sx={{ color: colors.green, fontSize: '30px' }} />
                  <Typography level="body-md" sx={{ color: "neutral.500", lineHeight: '1.2rem' }}>{registrationMessage}</Typography>
                </Box> :
                <>
                  <FormControl sx={{ mt: 2, mb: 1 }}>
                    <FormLabel>Email Address</FormLabel>
                    <Input required onChange={({ target: { value }}) => setEmail(value)} value={email} autoFocus type="email" placeholder="Your email address" />
                  </FormControl>
                  <FormControl sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button sx={{ mt: 1 }} type="submit" disabled={loading}>Register</Button>
                    <Button sx={{ mt: 1 }} variant="outlined" color="neutral" onClick={() => { setEmailSelected(true); setRegisterSelected(false) }}>Already have an account?</Button>
                  </FormControl>
                </>}
            </form>
          </Box>}
        <Box textAlign="center" sx={{ lineHeight: 1, mt: 3, color: colors.gray3, fontSize: fontSizes.sm, fontWeight: 300 }}>
          By continuing, you agree to our <Link sx={{ color: colors.gray3, fontSize: fontSizes.sm }} href="https://homemaster.ph/terms-and-conditions" target="_blank">Terms and Conditions</Link> and <Link sx={{ color: colors.gray3, fontSize: fontSizes.sm }} href="https://homemaster.ph/privacy-policy" target="_blank">Privacy Policy</Link>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button color="neutral" variant="outlined" onClick={() => navigate('/customer_login')}>Log in as Client?</Button>
        </Box>
      </Box> : <Box sx={{ height: '330px', display: 'flex', justifyContent: 'center', pt: 10 }}><BarLoader color={colors.primary} width={150} /></Box>}
    </Box>
  )
}
