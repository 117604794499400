import { Box } from '@mui/joy'
import { colors, fontSizes } from '../layouts/Theme'
import JobType from '../types/job-type'

const jobTypeLabel = {
  free: '',
  promoted: 'Promoted',
  high_value: 'High Value'
}

const jobTypeColor = {
  free: '',
  promoted: colors.green,
  high_value: colors.red
}

export default function JobTypeBadge({ jobType, mt = 0, mb = 2 }: { jobType: JobType['jobType'], mt?: number, mb?: number }) {
  if (jobType === 'free') {
    return <></>
  } else {
    return (
      <Box sx={{ position: 'relative', mb: '40px' }}>
        <Box sx={{
          position: 'absolute',
          left: '-20px',
          top: '-5px',
          mb, mt,
          display: 'flex',
          width: 'fit-content',
          fontWeight: 600,
          borderRadius: '0 6px 6px 0',
          fontSize: fontSizes.sm,
          p: '3px 18px',
          color: 'white',
          bgcolor: jobTypeColor[jobType]
        }}>
          {jobTypeLabel[jobType]}
        </Box>
      </Box>
    )
  }
}
