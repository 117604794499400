import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '../pages/ErrorFallback'
import LayoutContextProvider from './LayoutContextProvider'
import { Box, Grid, Link, Typography } from '@mui/joy'
import Alert from './Alert'
import { Suspense } from 'react'
import { Outlet, Link as NavLink } from 'react-router-dom'
import LoadingScreen from './LoadingScreen'
import { colors, fontFamilies } from './Theme'

export default function PublicLayout() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LayoutContextProvider>
        <Box sx={{ p: { xs: 2, md: 10, lg: 15 }, pt: { xs: 3, md: 3, lg: 4 } }}>
          <Alert />
          <Grid container>
            <Grid xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                <Link component={NavLink} to="/">
                  <img src="/logo.png" width="140px" />
                </Link>
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: colors.gray3,
                    fontFamily: fontFamilies.title,
                    textAlign: 'center',
                    mt: '3px',
                  }}
                >
                  Philippines
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12}>
            </Grid>
          </Grid>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </Box>
      </LayoutContextProvider>
    </ErrorBoundary>
  )
}
