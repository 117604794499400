import { TypedDocumentNode, gql } from '@apollo/client'

export const GENERATE_INVOICE_URL: TypedDocumentNode<{ generateInvoiceUrl: { errors: string[], invoiceUrl: string }}, { userType: string, sku: string, redirectUrl: string }> = gql`
  mutation GenerateInvoiceUrl($userType: String!, $sku: String!, $redirectUrl: String!) {
    generateInvoiceUrl(input: { userType: $userType, sku: $sku, redirectUrl: $redirectUrl }) {
      errors
      invoiceUrl
    }
  }
`
