import { Box, Typography } from '@mui/joy'
import { colors, fontSizes } from '../layouts/Theme'
import { useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { GENERATE_INVOICE_URL } from '../graphql/invoice-queries'
import { useContext } from 'react'
import { LayoutContext } from '../layouts/LayoutContextProvider'
import JobType from '../types/job-type'
import { CustomerContext } from '../layouts/customer/CustomerContextProvider'

export interface JobTypeSelectorProps {
  onJobTypeSelect: (jobType: JobType['jobType']) => void,
  setGeneratingInvoice: (invoiceGenerating: boolean) => void,
  onInvoiceNotRequired: () => void
}

export default function JobTypeSelector({ onJobTypeSelect, setGeneratingInvoice, onInvoiceNotRequired }: JobTypeSelectorProps) {
  const location = useLocation()
  const currentUrl = `${window.location.origin}${location.pathname}`
  const { showAlert } = useContext(LayoutContext)
  const user = useContext(CustomerContext)
  const { discountPercentage } = user
  const hasDiscount = discountPercentage > 0

  const [generateInvoiceUrl] = useMutation(GENERATE_INVOICE_URL, {
    onCompleted: (({ generateInvoiceUrl: { invoiceUrl, errors }}) => {
      if (!errors.length) {
        window.location.href = invoiceUrl
      } else {
        showAlert("Something went wrong and we're working on it!", 'danger')
      }
    })
  })

  function computeDiscountFor(amount: number) {
    return amount - (amount * (discountPercentage / 100))
  }

  function handleJobTypeClick(jobType: JobType['jobType']) {
    onJobTypeSelect(jobType)

    if (jobType === 'promoted' && (user.walletBalance - 100) < 0) {
      setGeneratingInvoice(true)
      generateInvoiceUrl({ variables: { userType: 'customer', sku: 'promoted_job', redirectUrl: `${currentUrl}?job_type=promoted` }})
    } else if (jobType === 'high_value' && (user.walletBalance - 1000) < 0) {
      setGeneratingInvoice(true)
      generateInvoiceUrl({ variables: { userType: 'customer', sku: 'high_value_job', redirectUrl: `${currentUrl}?job_type=high_value` }})
    } else {
      onInvoiceNotRequired()
    }
  }

  return (
    <Box>
      <Typography level="h2">Mamili ng Klase ng Job Post</Typography>
      {hasDiscount &&
        <Box sx={{ mt: 1 }}>
          <Typography level="title-md" sx={{ mb: 2, color: colors.green }}>Meron kang {discountPercentage}% discount mula sa Referral Code</Typography>
        </Box>}
      <Box sx={{ display: 'flex', gap: 2, mt: 2, flexDirection: 'column' }}>
        <Box sx={{ p: 2, border: `1px solid ${colors.gray2}`, borderRadius: '8px', width: '100%', '&:hover': { cursor: 'pointer' } }} onClick={() => handleJobTypeClick('free')}>
          <Typography level="title-lg">Regular Job Post</Typography>
          <Typography level="body-md" sx={{ mb: 1, color: colors.gray3 }}>Price: Free</Typography>
          <ul style={{ fontSize: fontSizes.sm, lineHeight: '1.1em' }}>
            <li style={{ marginBottom: '8px' }}>Makakatanggap ka ng maximum of 15 estimates</li>
            <li>Best for clients na willing mag antay ng estimates</li>
          </ul>
        </Box>
        <Box sx={{ p: 2, border: `1px solid ${colors.green}`, borderRadius: '8px', width: '100%', bgcolor: colors.lightGreen, '&:hover': { cursor: 'pointer' } }} onClick={() => handleJobTypeClick('promoted')}>
          <Typography level="title-lg">Promoted Job Post</Typography>
          <Typography level="body-md" sx={{ mb: 1, color: colors.gray3 }}>
            {hasDiscount ? <span>Price: <span style={{ textDecorationLine: 'line-through' }}>₱100</span> ₱{computeDiscountFor(100)}</span> : <span>Price: ₱100</span>}
          </Typography>
          <ul style={{ fontSize: fontSizes.sm, lineHeight: '1.1em' }}>
            <li style={{ marginBottom: '8px' }}>Makakatanggap ka ng maximum of 25 estimates</li>
            <li style={{ marginBottom: '8px' }}>Ang iyong post ay may Promoted Badge at nasa taas ng free job posts</li>
            <li>Best for clients na gustong makatanggap agad ng estimates</li>
          </ul>
        </Box>
        <Box sx={{ p: 2, border: `1px solid ${colors.red}`, borderRadius: '8px', width: '100%', bgcolor: colors.lightRed, '&:hover': { cursor: 'pointer' } }} onClick={() => handleJobTypeClick('high_value')}>
          <Typography level="title-lg">High Value Job Post</Typography>
          <Typography level="body-md" sx={{ mb: 1, color: colors.gray3 }}>
            {hasDiscount ? <span>Price: <span style={{ textDecorationLine: 'line-through' }}>₱1,000</span> ₱{computeDiscountFor(1000)}</span> : <span>Price: ₱1,000</span>}
          </Typography>
          <ul style={{ fontSize: fontSizes.sm, lineHeight: '1.1em' }}>
            <li style={{ marginBottom: '8px' }}>Makakatanggap ka ng maximum of 35 estimates</li>
            <li style={{ marginBottom: '8px' }}>Ang iyong post ay may High Value Badge at nasa taas ng promoted at free job posts</li>
            <li>Best for clients na may ipapagawa worth ₱500,000 pataas</li>
          </ul>
        </Box>
      </Box>
    </Box>
  )
}
