//@ts-expect-error: This is needed by Rails
import React, { useContext, useState } from 'react'
import { Box, Modal, ModalDialog } from "@mui/joy"
import JobTypeSelector, { JobTypeSelectorProps } from './JobTypeSelector'

export default function JobTypeSelectorModal({
  onJobTypeSelect, setGeneratingInvoice, onInvoiceNotRequired, open, setOpen
}: JobTypeSelectorProps & { open: boolean, setOpen: (val: boolean) => void }) {

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog sx={{ width: { xs: '90%', md: '50%' }}}>
        <Box sx={{ overflow: 'auto' }}>
          <JobTypeSelector onJobTypeSelect={onJobTypeSelect} setGeneratingInvoice={setGeneratingInvoice} onInvoiceNotRequired={onInvoiceNotRequired} />
        </Box>
      </ModalDialog>
    </Modal>
  )
}
