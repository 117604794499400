import { gql, TypedDocumentNode } from '@apollo/client';
import { AmbassadorCustomerType } from '../types/customer-type';

export const GET_AMBASSADOR_CUSTOMERS: TypedDocumentNode<{ ambassadorCustomers: AmbassadorCustomerType[] }, { q?: string }> = gql`
  query GetAmbassadorCustomers($q: String) {
    ambassadorCustomers(q: $q) {
      id
      fullName
      referralVerified
      createdAt
    }
  }
`
