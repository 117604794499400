import { Box, Typography } from '@mui/joy'
import { PlumbingJobAttributes } from '../../types/job-type'
import { colors } from '../../layouts/Theme'

export default function PlumbingDetails({ attrs }: { attrs: PlumbingJobAttributes }) {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Service Needed</Typography>
        <Typography level="title-sm">{attrs.plumbingService}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Issue</Typography>
        <Typography level="title-sm">{attrs.issue}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Symptoms</Typography>
        <Typography level="title-sm">{attrs.symptoms}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>When did the issue started?</Typography>
        <Typography level="title-sm">{attrs.frequency}</Typography>
      </Box>
    </Box>
  )
}
