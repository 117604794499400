import { Box, Grid } from '@mui/joy'
import CustomerLoginOptions from '../../components/CustomerLoginOptions'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

export default function CustomerLoginPage() {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    localStorage.setItem('hm:source', searchParams.get('ref') || 'organic')
  }, [])

  return (
    <Grid container justifyContent="center">
      <Grid xs={12} md={4}>
        <Box sx={{ p: '20px', backgroundColor: '#FFF', borderRadius: '20px' }}>
          <CustomerLoginOptions />
        </Box>
      </Grid>
    </Grid>
  )
}
