import { Box, FormControl, FormLabel, Select, Option } from '@mui/joy'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { JobAttributes, PlumbingJobAttributes } from '../../types/job-type'

export default function PlumbingForm({ control, attrs }: {
  register: UseFormRegister<JobAttributes>
  control: Control<JobAttributes>
  attrs: PlumbingJobAttributes
}) {

  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: '50%' }}}>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Plumbing Service</FormLabel>
        <Controller
          name="categoryAttrsValues.plumbingService"
          defaultValue={attrs?.plumbingService}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="What plumbing service do you need?"
            >
              <Option value="Drain Cleaning">Drain Cleaning</Option>
              <Option value="Leak Detection and Repair">Leak Detection and Repair</Option>
              <Option value="Faucet and Shower Repair">Faucet and Shower Repair</Option>
              <Option value="Toilet Repair and Installation">Toilet Repair and Installation</Option>
              <Option value="Water Heater Repair and Installation">Water Heater Repair and Installation</Option>
              <Option value="Pipe Repair and Replacement">Pipe Repair and Replacement</Option>
              <Option value="Sewer Line Services">Sewer Line Services</Option>
              <Option value="Septic Tank Repair and Installation">Septic Tank Installation</Option>
              <Option value="Siphoning and Declogging">Siphoning and Declogging</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Issue</FormLabel>
        <Controller
          name="categoryAttrsValues.issue"
          defaultValue={attrs?.issue}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="What issue are you encountering?"
            >
              <Option value="Clogged Drains">Clogged Drains</Option>
              <Option value="Leaky Faucets">Leaky Faucets</Option>
              <Option value="Low Water Pressure">Low Water Pressure</Option>
              <Option value="Running Toilets">Running Toilets</Option>
              <Option value="Burst Pipes">Burst Pipes</Option>
              <Option value="Water Heater Issues">Water Heater Issues</Option>
              <Option value="Leaking Pipes">Leaking Pipes</Option>
              <Option value="Backed-Up Sewer Lines">Backed-Up Sewer Lines</Option>
              <Option value="Water Heater Tank Leaks">Water Heater Tank Leaks</Option>
              <Option value="Sewer Odor">Sewer Odor</Option>
              <Option value="Blocked Vent Pipes">Blocked Vent Pipes</Option>
              <Option value="No Hot Water">No Hot Water</Option>
              <Option value="Slab Leaks">Slab Leaks</Option>
              <Option value="Dripping Pipes">Dripping Pipes</Option>
              <Option value="Water Discoloration">Water Discoloration</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Symptoms</FormLabel>
        <Controller
          name="categoryAttrsValues.symptoms"
          defaultValue={attrs?.symptoms}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Select the specific symptoms"
            >
              <Option value="Unusual sounds (e.g., gurgling, bubbling)">Unusual sounds (e.g., gurgling, bubbling)</Option>
              <Option value="Water pooling around fixtures or pipes">Water pooling around fixtures or pipes</Option>
              <Option value="Foul odors coming from drains or pipes">Foul odors coming from drains or pipes</Option>
              <Option value="Decreased water flow or pressure">Decreased water flow or pressure</Option>
              <Option value="Visible water stains or damage on walls or ceilings">Visible water stains or damage on walls or ceilings</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Since when did you notice this issue?</FormLabel>
        <Controller
          name="categoryAttrsValues.frequency"
          defaultValue={attrs?.frequency}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Select the rough timeframe"
            >
              <Option value="Few hours ago">Few hours ago</Option>
              <Option value="Few days ago">Few days ago</Option>
              <Option value="A week or more ago">A week or more ago</Option>
              <Option value="Several months ago">Several months ago</Option>
              <Option value="Ongoing issue for years">Ongoing issue for years</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
    </Box>
  )
}
