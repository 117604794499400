//@ts-expect-error: This is needed by Rails
import React, { useContext } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/joy'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import { GET_LEAD } from '../../graphql/job-queries'
import { colors, fontSizes } from '../../layouts/Theme'
import dayjs from 'dayjs'
import ImagesPreview from '../../components/ImagesPreview'
import DisplayMinMax from '../../components/DisplayMinMax'
import DisplayTimeline from '../../components/DisplayTimeline'
import SendQuoteForm from '../../components/SendQuoteForm'
import { LayoutContext } from '../../layouts/LayoutContextProvider'
import CategoryIcon from '../../components/CategoryIcon'
import { GET_ESTIMATE_FOR_JOB } from '../../graphql/estimate-queries'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { isEmpty } from 'lodash'
import { renderCategoryDetails } from '../../utils/render-utils'
import JobTypeBadge from '../../components/JobTypeBadge'

export default function LeadPage() {
  const { jobUuid } = useParams()
  const [searchParams] = useSearchParams()
  const { data: { lead } } = useSuspenseQuery(GET_LEAD, { variables: { uuid: jobUuid! }})
  const { data: { estimateForJob } } = useSuspenseQuery(GET_ESTIMATE_FOR_JOB, { variables: { uuid: jobUuid! }})
  const { jobType, summary, createdAt, images, budgetMin, budgetMax, timeline, description, jobCategory, categoryAttrsValues } = lead
  const navigate = useNavigate()
  const { showAlert } = useContext(LayoutContext)
  const fromNotifications = searchParams.get('ref') === 'notifications'

  function onQuoteSubmitted() {
    navigate('/business/leads')
    showAlert("Your estimate was sent!")
  }

  return (
    <Grid container>
      <Grid xs={12}>
        <IconButton
          sx={{ mb: 2, pl: '5px', pr: '10px' }}
          onClick={() => navigate(fromNotifications ? '/business/notifications' : '/business/leads')}
        >
          <KeyboardArrowLeftIcon sx={{ mr: 0 }} />
          Back
        </IconButton>
      </Grid>
      <Grid xs={12} lg={7}>
        <Box
          sx={{
            scrollMarginTop: '20px',
            backgroundColor: '#FFF',
            borderRadius: '20px',
            position: 'relative',
            p: '20px',
          }}
        >
          <JobTypeBadge jobType={jobType} />
          <Box sx={{ display: 'flex' }}>
            <CategoryIcon categorySlug={jobCategory.slug} />
            <Box>
              <Box sx={{ fontSize: fontSizes.regular, fontWeight: 600 }}>
                {summary}
              </Box>
              <Box sx={{ fontSize: fontSizes.sm, color: colors.gray2, mt: '-2px' }}>
                Posted {dayjs(createdAt).fromNow()}
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: 2, fontSize: fontSizes.sm, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            <DisplayMinMax valueMin={budgetMin} valueMax={budgetMax} />
            <DisplayTimeline timeline={timeline} />
          </Box>
          <ImagesPreview images={images} />
          {!isEmpty(categoryAttrsValues) && renderCategoryDetails(jobCategory.slug, categoryAttrsValues)}
          {description &&
            <Box sx={{ mt: 1, whiteSpace: 'pre-line' }}>
              <Typography level="title-sm" sx={{ mb: '10px', color: colors.gray3 }}>Description</Typography>
              <Typography level="title-sm">{description}</Typography>
            </Box>}
        </Box>
      </Grid>
      <Grid xs={12} lg={7}>
        <Box
          sx={{
            mt: 3,
            backgroundColor: '#FFF',
            borderRadius: '20px',
            position: 'relative',
            p: '20px',
          }}
        >
          <SendQuoteForm
            job={lead}
            estimate={estimateForJob}
            onClose={() => navigate('/business/leads')}
            onCompleted={onQuoteSubmitted}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
